import $ from 'jquery';
import 'bootstrap';
import Swiper, {Autoplay} from 'swiper';
import AOS from 'aos';
Swiper.use([Autoplay]);
const general = {
    "mqBreakpoints": {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1156
    },
};

export default general;

(function ($) {
    $(document).ready(function () {
        AOS.init();

        const swiperConfig = {
            speed: 0,
            spaceBetween: 0,
            loop: true,
            slidesPerView: 1,
            autoplay: {
                delay: 1000,
            },
        };

        var swiper1 = new Swiper('.swiper-1', swiperConfig);

        setTimeout(function() {
            var swiper2 = new Swiper('.swiper-2', swiperConfig);
        }, 250);

        setTimeout(function() {
            var swiper3 = new Swiper('.swiper-3', swiperConfig);
        }, 500);

        setTimeout(function() {
            var swiper4 = new Swiper('.swiper-4', swiperConfig);
        }, 750);
    });
})($);

